class CedulaUruguaya {
    getValidationDigit(ci) {
        let strCI = ci.toString();
        let a = 0;
        if (strCI.length <= 6) {
            strCI = '0'.repeat(7 - strCI.length) + strCI;
        }
        for (let i = 0; i < 7; i++) {
            a += (parseInt("2987634"[i]) * parseInt(strCI[i])) % 10;
        }
        if (a % 10 === 0) {
            return 0;
        } else {
            return 10 - a % 10;
        }
    }

    cleanCI(ci) {
        return parseInt(ci.toString().replace("-", "").replace('.', ''));
    }

    validateCI(ci) {
        ci = this.cleanCI(ci);
        const dig = parseInt(ci.toString()[ci.toString().length - 1]);
        const ciSinDig = ci.toString().slice(0, -1);
        return dig === this.getValidationDigit(ciSinDig);
    }
}

function validarNroDoc(nroDoc, tipoDoc) {
    if (nroDoc === '') {
        return "Número de documento es obligatorio";
    }
    if (!/^\d+$/.test(nroDoc)) {
        return "Nro de documento inválido. Ingresar solo números";
    }
    if (nroDoc.length < 5 || nroDoc.length > 20) {
        return "Nro de documento inválido";
    }
    if (tipoDoc === 'CI') {
        let cedula = new CedulaUruguaya();
        if (!cedula.validateCI(nroDoc)) {
            return "Cédula inválida";
        }
    }
    return ""
}

function validarFechaNacimiento(fechaNacimiento) {
    // Verificar si la fecha de nacimiento no está vacía
    if (!fechaNacimiento) {
        return "La fecha de nacimiento es obligatoria.";
    }

    // Verificar si la fecha de nacimiento tiene el formato dd/mm/yyyy
    let partesFecha = fechaNacimiento.split("/");
    if (partesFecha.length !== 3) {
        return "El formato de fecha de nacimiento debe ser dd/mm/yyyy.";
    }

    let dia = parseInt(partesFecha[0], 10);
    let mes = parseInt(partesFecha[1], 10);
    let año = parseInt(partesFecha[2], 10);

    // Verificar si la fecha es válida
    if (isNaN(dia) || isNaN(mes) || isNaN(año)) {
        return "La fecha de nacimiento no es válida.";
    }

    // Verificar si el mes es válido (entre 1 y 12)
    if (mes < 1 || mes > 12) {
        return "El mes de nacimiento no es válido.";
    }

    // Verificar si el día es válido
    let ultimoDiaMes = new Date(año, mes, 0).getDate();
    if (dia < 1 || dia > ultimoDiaMes) {
        return "El día de nacimiento no es válido.";
    }

    // Verificar si la fecha de nacimiento es menor que la fecha actual
    let fechaActual = new Date();
    let fechaNac = new Date(año, mes - 1, dia); // Restamos 1 al mes porque en JavaScript los meses van de 0 a 11
    if (fechaNac >= fechaActual) {
        return "La fecha de nacimiento no puede ser mayor o igual a la fecha actual.";
    }

    // Calcular edad
    let edad = fechaActual.getFullYear() - año;
    let mesActual = fechaActual.getMonth() + 1; // Sumamos 1 al mes actual para comparar correctamente
    let diaActual = fechaActual.getDate();

    // Verificar si la edad cumple con las restricciones (mayor o igual a 18 años)
    if (edad < 18 || (edad === 18 && (mes > mesActual || (mes === mesActual && dia > diaActual)))) {
        return "Los menores de 18 años no pueden inscribirse por reglamento del programa";
    }

    if (edad >= 120) {
        return "Ingrese una edad valida";
    }
    return "";
}
function validarNumeroTelefono(telefono, localidad, tipo) {
    if (telefono.trim()?.length === 0) {
        return ""
    }
    // Verificar si el número es un string numérico
    if (!/^\d+$/.test(telefono)) {
        return `El número de ${tipo} debe contener solo dígitos.`;
    }

    // Verificar la longitud del número
    if (telefono.trim().length < 5 || telefono.trim().length > 30) {
        return `La longitud del número de ${tipo} debe estar entre 5 y 30 caracteres.`;
    }
    if ((localidad !== 3002 && localidad !== 3003 && localidad !== 3001 && localidad !== undefined)) {
        const numTelefono = parseInt(telefono);
        if (numTelefono === 99999999 || numTelefono === 98999999) {
            return `${tipo} inválido.`;
        }

        if (!/^[024]/.test(telefono)) {
            return `${tipo} inválido: El número debe comenzar con 2, 4 o 0.`;
        }

        // Verificar las condiciones adicionales para los casos específicos
        if (telefono.startsWith('2') || telefono.startsWith('4')) {
            if (telefono.trim().length > 8) {
                return `${tipo} inválido: Si comienza con 2 o 4, el máximo es de 8 dígitos.`;
            }
        } else if (telefono.startsWith('0')) {

            if (!/^0|9/.test(telefono[1])) {
                return `${tipo} inválido: Después del 0, debe seguir un 0 o un 9.`;
            }

            if (telefono.startsWith('09')) {
                if (telefono.length !== 9) {
                    return `${tipo} inválido: Si comienza con 09. Debe tener 9 dígitos.`;
                }
            }
        }
    }

    return "";
}

function validarMail(mail) {
    if (mail.trim()?.length === 0) {
        return ""
    }
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (mail.trim().length < 3 || mail.trim().length > 50)
        return "Email invalido. Debe tener entre 3 y 50 caracteres"
    if (!regex.test(mail))
        return "Email invalido. Debe contener un @ en el medio y finalizar con un .dominio"
    return ""
}

const verificaciones = (data) => {
    const verificaciones = { celular: "", fecha_nacimiento: "", genero: "", nro_doc: "", telefono: "", tipo_doc: "", nombre: "", apellido: "", mail: "", direccion: "" }

    if (data.tipo_doc === "")
        verificaciones.tipo_doc = "Tipo documento es obligatorio"
    if (data.nombre.trim().length < 3 || data.nombre.trim().length > 30)
        verificaciones.nombre = "Nombre invalido. Debe tener entre 3 y 30 caracteres"
    if (data.apellido.trim().length < 3 || data.apellido.trim().length > 30)
        verificaciones.apellido = "Apellido invalido. Debe tener entre 3 y 30 caracteres"
    if (data.genero === "")
        verificaciones.genero = "Genero es obligatorio"
    if (data.direccion.trim().length < 3 || data.direccion.trim().length > 100)
        verificaciones.direccion = "Direccion invalida. Debe tener entre 3 y 100 caracteres"
    if (data?.pais === "")
        verificaciones.pais = "Pais es obligatorio."
    if (data?.pais === 'URUGUAY') {
        if (data?.departamento === "") {
            verificaciones.departamento = "Departamento es obligatorio."
        }
        if (data?.localidad === "") {
            verificaciones.localidad = "Localidad es obligatorio."
        }
    }
    verificaciones.mail = validarMail(data.mail)
    verificaciones.nro_doc = validarNroDoc(data.nro_doc, data.tipo_doc)
    verificaciones.fecha_nacimiento = validarFechaNacimiento(data.fecha_nacimiento)
    verificaciones.celular = validarNumeroTelefono(data.celular, data.localidad, 'celular')
    verificaciones.telefono = validarNumeroTelefono(data.telefono, data.localidad, 'telefono')

    if (data.celular === "" && data.telefono === "" && data.mail === "") {
        verificaciones.mail = ""
        verificaciones.celular = "Debe agregar por lo menos un celular, telefono y/o un email"
    }
    return verificaciones

}


export default verificaciones
